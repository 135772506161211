<template>
  <div class="lobbyspace-templates">
    <div class="lobbyspace-templates__header">
      <h2 class="lobbyspace-templates__heading">
        {{ $t("pages.templates.heading.lobbyspace") }}
      </h2>
    </div>
    <div class="lobbyspace-templates__template-wrapper">
      <div
        class="lobbyspace-templates__template"
        v-for="template in sortedTemplates"
        :key="template.id"
      >
        <Template
          :template="template.data"
          :is-lobbyspace="true"
          :id="template.id"
          :team-id="team.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Template from "@/components/Template";

export default {
  components: {
    Template
  },
  computed: {
    ...mapGetters("templates", {
      templates: "getLobbySpaceTemplates"
    }),
    ...mapGetters("teams", {
      team: "getCurrentTeam"
    }),
    sortedTemplates() {
      let t = [...this.templates];

      return t
        .sort((a, b) => {
          return b.data.lastChangedDate.localeCompare(a.data.lastChangedDate);
        })
        .filter(
          template =>
            template.data?.status !== "archived" &&
            template.data?.status !== "deleted"
        );
    }
  },
  mounted() {
    this.fetchLobbySpaceTemplates();
  },
  methods: {
    ...mapActions("templates", ["fetchLobbySpaceTemplates"])
  }
};
</script>

<style lang="scss">
.lobbyspace-templates {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__heading {
    font-size: 28px;
    font-weight: 600;
  }

  &__template-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__template {
    @apply w-full cursor-pointer;
  }
}

@media screen and (min-width: 900px) {
  .lobbyspace-templates {
    &__template {
      @apply w-auto mr-4 mb-4;
    }
  }
}
</style>
