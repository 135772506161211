<template>
  <div class="root-templates">
    <div class="root-templates__header">
      <h2 class="root-templates__heading" v-if="rootTeam">
        {{ rootTeam.name }}
      </h2>
    </div>
    <div class="root-templates__template-wrapper">
      <div
        class="root-templates__template"
        v-for="template in sortedTemplates"
        :key="template.id"
      >
        <Template :template="template.data" :is-root="true" :id="template.id" />
      </div>
    </div>
  </div>
</template>

<script>
import Template from "@/components/Template";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Template
  },
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      rootTeam: "getRootTeam"
    }),
    ...mapGetters("templates", {
      templates: "getRootTemplates"
    }),
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    sortedTemplates() {
      let t = [...this.templates];

      return t
        .sort((a, b) => {
          return b.data.lastChangedDate.localeCompare(a.data.lastChangedDate);
        })
        .filter(
          template =>
            template.data?.status !== "archived" &&
            template.data?.status !== "deleted"
        );
    }
  },
  mounted() {
    const storedTeam = sessionStorage.getItem("currentTeam");

    if (!this.user?.currentTeam && !storedTeam) {
      this.fetchUserProfile().then(() => {
        this.fetchCurrentTeam(this.user.currentTeam).then(() => {
          this.fetchRootTeam(this.team.rootTeam).then(() => {
            this.fetchRootTemplates(this.team.rootTeam);
          });
        });
      });
    } else if (storedTeam) {
      this.fetchCurrentTeam(storedTeam).then(() => {
        this.fetchRootTeam(this.team.rootTeam).then(() => {
          this.fetchRootTemplates(this.team.rootTeam);
        });
      });
    } else {
      this.fetchCurrentTeam(this.user.currentTeam).then(() => {
        this.fetchRootTeam(this.team.rootTeam).then(() => {
          this.fetchRootTemplates(this.team.rootTeam);
        });
      });
    }
  },
  methods: {
    ...mapActions("templates", ["fetchTemplates", "fetchRootTemplates"]),
    ...mapActions("teams", ["fetchCurrentTeam", "fetchRootTeam"]),
    ...mapActions("user", ["fetchUserProfile"])
  }
};
</script>

<style lang="scss">
.root-templates {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__heading {
    font-size: 20px;
    font-weight: 600;
  }

  &__create-btn {
    align-self: flex-end;

    &__icon {
      margin-right: 0.5rem;
    }
  }

  &__template-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__template {
    @apply w-full cursor-pointer;
  }
}

@media screen and (min-width: 900px) {
  .root-templates {
    &__template {
      @apply w-auto mr-4 mb-4;
    }
  }
}
</style>
