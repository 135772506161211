<template>
  <div class="templates">
    <div
      class="templates__sidebar--desktop templates__sidebar"
      v-if="allLoaded"
    >
      <div class="templates__sidebar-block" v-if="showFirstTemplateLinkBlock">
        <div class="templates__sidebar-block-heading">
          {{ team.name }}
        </div>

        <router-link
          to="/templates/organization"
          class="templates__sidebar-link"
          :class="{
            'templates__sidebar-link--active':
              $route.path === '/templates/organization'
          }"
        >
          <inline-svg
            class="templates__sidebar-link-icon"
            :src="require(`../../assets/icons/Templates.svg`)"
            aria-label="Templates"
          ></inline-svg>
          {{ $t("pages.templates.orga") }}
        </router-link>

        <router-link
          to="/templates/archive"
          class="templates__sidebar-link"
          :class="{
            'templates__sidebar-link--active':
              $route.path === '/templates/archive'
          }"
        >
          <inline-svg
            class="templates__sidebar-link-icon"
            :src="require(`../../assets/icons/Archive.svg`)"
            aria-label="Archive"
          ></inline-svg>
          {{ $t("pages.templates.archive") }}
        </router-link>
      </div>

      <div
        class="templates__sidebar-divider"
        v-if="showFirstTemplateLinkBlock"
      ></div>

      <div class="templates__sidebar-block" v-if="showSecondTemplateLinkBlock">
        <div class="templates__sidebar-block-heading">
          {{ $t("pages.templates.linked") }}
        </div>

        <router-link
          to="/templates/headquarter"
          class="templates__sidebar-link"
          :class="{
            'templates__sidebar-link--active':
              $route.path === '/templates/headquarter'
          }"
        >
          <div
            class="templates__sidebar-link-logo"
            v-if="parentTeam.avatar"
            :style="{
              'background-image': 'url(' + parentTeam.avatar + ')'
            }"
          ></div>
          <ReplacementLogo
            :name="parentTeam.name"
            v-else
            class="templates__sidebar-link-logo--unset"
          />
          {{ parentTeam.name }}
        </router-link>

        <router-link
          to="/templates/root"
          class="templates__sidebar-link"
          :class="{
            'templates__sidebar-link--active': $route.path === '/templates/root'
          }"
          v-if="rootTeam && rootTeam.id !== parentTeam.id"
        >
          <div
            class="templates__sidebar-link-logo"
            v-if="rootTeam.avatar"
            :style="{
              'background-image': 'url(' + rootTeam.avatar + ')'
            }"
          ></div>
          <ReplacementLogo
            :name="rootTeam.name"
            v-else
            class="templates__sidebar-link-logo--unset"
          />
          {{ rootTeam.name }}
        </router-link>
      </div>

      <div
        class="templates__sidebar-divider"
        v-if="showSecondTemplateLinkBlock"
      ></div>

      <div
        class="templates__sidebar-block"
        v-if="team.lobbySpaceTemplateAccess"
      >
        <div class="templates__sidebar-block-heading">
          {{ $t("pages.templates.discover") }}
        </div>
        <router-link
          to="/templates/smart"
          class="templates__sidebar-link"
          :class="{
            'templates__sidebar-link--active':
              $route.path === '/templates/smart'
          }"
        >
          <inline-svg
            class="templates__sidebar-link-smart-icon"
            :src="require(`../../assets/icons/AI.svg`)"
            aria-label="asdf"
          ></inline-svg>
          {{ $t("pages.templates.lobbyspace") }}
        </router-link>
        <!-- New navigation item for "Request a Template" -->
       <!--<a
          href="https://docs.google.com/forms/d/e/1FAIpQLScRh9-1hlZ0O3vikvheaIAGNxAgXfm2CTtNaf0Cl2Xq8zs67A/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
          class="templates__sidebar-link"
        >
          <inline-svg
            class="templates__sidebar-link-icon"
            :src="require(`../../assets/icons/Request.svg`)"
            aria-label="Request Template"
          ></inline-svg>
          {{ $t("pages.templates.request") }}
       </a>-->
      </div>
    </div>

    <SideBarMobile
      class="templates__sidebar--mobile"
      :list="templateLinks"
    ></SideBarMobile>

    <div class="templates__banner">
      <div class="templates__banner__wrapper">
        <div class="templates__banner__text">
          <h1 class="templates__banner__heading">
            {{ $t("pages.templates.banner.heading") }}
          </h1>
          <p>{{ $t("pages.templates.banner.text") }}</p>
        </div>
        <img
          class="templates__banner__image"
          :src="require(`../../assets/img/template-banner-image.png`)"
        />
      </div>
    </div>
    <div class="templates__wrapper">
      <component class="templates__content" :is="componentName"></component>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import ReplacementLogo from "@/components/ReplacementLogo";
import SideBarMobile from "@/components/SideBarMobile";
import Headquarter from "./Headquarter";
import Smart from "./Lobbyspace";
import Organization from "./Organization";
import Archive from "./ArchivedTemplates";
import Root from "./RootTemplates.vue";
import { templateLinks } from "@/schema/sideBar";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SideBar,
    SideBarMobile,
    Headquarter,
    Organization,
    Archive,
    Root,
    Smart,
    ReplacementLogo
  },
  data: () => ({
    allLoaded: true
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      parentTeam: "getParentTeam",
      childSettings: "getChildSettings",
      rootTeam: "getRootTeam"
    }),
    showFirstTemplateLinkBlock() {
      if (this.parentTeam && !this.childSettings?.createTemplates) {
        return false;
      }

      return true;
    },
    showSecondTemplateLinkBlock() {
      return this.parentTeam ? true : false;
    },
    templateLinks() {
      let links = [];
      let parentTeamLink = [
        {
          name: this.parentTeam?.name,
          link: "/templates/headquarter"
        }
      ];

      let rootTeamLink = [
        {
          name: this.rootTeam?.name,
          link: "/templates/root"
        }
      ];

      let smartTeamLink = [
        {
          name: this.$t("pages.templates.lobbyspace"),
          link: "/templates/smart"
        }
      ];

      console.log(this.showFirstTemplateLinkBlock);
      if (this.showFirstTemplateLinkBlock) {
        links = [...templateLinks];
        links[0].name = this.$t("pages.templates.orga");
        links[1].name = this.$t("pages.templates.archive");
      }

      if (this.showSecondTemplateLinkBlock) {
        if (this.rootTeam && this.rootTeam.id !== this.parentTeam.id) {
          links = [...links, ...parentTeamLink, ...rootTeamLink];
        } else {
          links = [...links, ...parentTeamLink];
        }
      }

      if (this.team?.lobbySpaceTemplateAccess) {
        links = [...links, ...smartTeamLink];
      }

      return links;
    },
    componentName() {
      return this.$route.params.id;
    },
    isMobile() {
      return window.innerWidth < 900 ? true : false;
    }
  },
  watch: {
    team() {
      if (!this.team.parentTeam && this.componentName === "headquarter") {
        this.$router.push("/templates/organization");
        this.allLoaded = true;
      }

      if (
        !this.team.lobbySpaceTemplateAccess &&
        this.componentName === "smart"
      ) {
        this.$router.push("/templates/organization");
      }

      if (this.parentTeam && !this.childSettings?.createTemplates) {
        if (this.$route.params.id !== "headquarter") {
          this.$router.push("/templates/headquarter");
        }
      }
    }
  },
  mounted() {
    this.resetTemplate();
  },
  methods: {
    ...mapActions("templates", ["resetTemplate"])
  }
};
</script>

<style lang="scss">
.templates {
  min-height: 100vh;

  &__sidebar {
    display: none;
  }

  &__banner {
    display: flex;
    justify-content: center;
    height: 168px;
    background: url("../../assets/img/template-banner-bg.png") 50% 50%;
    background-size: cover;
    color: #fff;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 0;

    &__wrapper {
      width: 100%;
      position: relative;
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }

    &__text {
      max-width: 376px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    &__heading {
      font-size: 36px;
      line-height: 44px;
      font-weight: 600;
      margin-bottom: 0;
      margin-top: 0;
      align-self: flex-start;
    }

    &__image {
      height: 168px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__content {
    padding: 4rem 1.5rem 1.5rem;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // text-align: left;
  }

  &__heading {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 36px;
  }

  &__list {
    display: flex;
  }

  &__item {
    margin-right: 1rem;
    min-width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .templates {
    &__wrapper {
      margin-left: 208px;
      display: flex;
      justify-content: center;
      padding: 2.25rem 0 0;
    }

    &__sidebar--mobile {
      display: none;
    }

    &__sidebar {
      display: block;
      height: 100vh;
      width: 208px;
      @apply border-l border-r border-ls-gray-200 bg-white fixed p-4;

      &-block {
        &:first-child {
          padding-top: 8px;

          .templates__sidebar-block-heading {
            padding: 0 8px 12px 8px;
          }
        }

        &-heading {
          @apply font-semibold text-black text-xs py-3 px-2;
        }
      }

      &-divider {
        height: 1px;
        @apply bg-ls-gray-200 w-full my-4;
      }

      &-link {
        @apply text-ls-gray-800 font-semibold text-xs cursor-pointer py-3 px-2 flex items-center;
        width: 100%;

        &:hover {
          @apply bg-ls-gray-100;
          border-radius: 8px;
        }

        &-logo {
          @apply bg-cover w-6 h-6 mr-2 rounded-lg;

          &--unset {
            @apply w-6 h-6 mr-2 rounded-lg;
          }
        }

        .templates__sidebar-link-icon {
          margin-right: 8px;

          path,
          rect {
            fill: #7b7b7c;
          }

          path.templates {
            stroke: none;
          }

          path.templates {
            fill: #7b7b7c;
          }
        }

        .templates__sidebar-link-smart-icon {
          margin-right: 8px;
        }

        &--active {
          @apply text-ls-purple;

          .templates__sidebar-link-icon {
            @apply text-ls-purple;

            path,
            rect {
              @apply fill-current;
            }

            path.templates {
              @apply fill-current;
            }
          }
        }
      }

      &__footer {
        @apply flex flex-col;
      }
    }

    &__banner {
      margin-left: 208px;
      display: flex;
      justify-content: center;
      height: 168px;
      background: url("../../assets/img/template-banner-bg.png") 50% 50%;
      background-size: cover;
      color: #fff;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 0;

      &__wrapper {
        width: 760px;
        position: relative;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: flex-start;
      }

      &__text {
        max-width: 376px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      &__heading {
        font-size: 36px;
        line-height: 44px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0;
        align-self: flex-start;
      }

      &__image {
        height: 168px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &__content {
      padding: 0;
      width: 760px;
    }
  }
}
</style>