<template>
  <div class="organization-templates">
    <div class="organization-templates__header">
      <h2 class="organization-templates__heading">
        {{ $t("pages.templates.heading.organization") }}
      </h2>
      <a
        :href="`/designer/?id=${team.id}`"
        v-if="showTemplateButton"
        class="only-desktop"
      >
        <Button theme="secondary" class="organization-templates__create-btn">
          <inline-svg
            class="organization-templates__create-btn__icon"
            :src="require(`../../assets/icons/Plus.svg`)"
            aria-label="Create"
          ></inline-svg>
          {{ $t("pages.templates.createButton") }}
        </Button>
      </a>
    </div>
    <div class="organization-templates__template-wrapper">
      <div
        class="organization-templates__template"
        v-for="template in sortedTemplates"
        :key="template.id"
      >
        <Template
          :template="template.data"
          :is-parent="false"
          :id="template.id"
          :team-id="team.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Template from "@/components/Template";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Button,
    Template
  },
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      parentTeam: "getParentTeam",
      childSettings: "getChildSettings"
    }),
    ...mapGetters("templates", {
      templates: "getTemplates"
    }),
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    sortedTemplates() {
      if (this.templates) {
        let t = [...this.templates];

        return t
          .sort((a, b) => {
            return b.data.lastChangedDate.localeCompare(a.data.lastChangedDate);
          })
          .filter(
            template =>
              template.data?.status !== "archived" &&
              template.data?.status !== "deleted"
          );
      }

      return [];
    },
    showTemplateButton() {
      if (this.team && this.team.users) {
        if (this.parentTeam) {
          return (
            this.childSettings?.createTemplates &&
            this.team.users[this.user.user_id].role !== "editor"
          );
        }

        return this.team.users[this.user.user_id].role !== "editor";
      }

      return false;
    }
  },
  mounted() {
    const storedTeam = sessionStorage.getItem("currentTeam");

    if (!this.user?.currentTeam && !storedTeam) {
      this.fetchUserProfile().then(() => {
        this.fetchCurrentTeam(this.user.currentTeam).then(() => {
          this.fetchTemplates(this.team.id);
        });
      });
    } else if (storedTeam) {
      this.fetchCurrentTeam(storedTeam).then(() => {
        this.fetchTemplates(this.team.id);
      });
    } else {
      this.fetchCurrentTeam(this.user.currentTeam).then(() => {
        this.fetchTemplates(this.team.id);
      });
    }
  },
  methods: {
    ...mapActions("templates", ["fetchTemplates"]),
    ...mapActions("teams", ["fetchCurrentTeam"]),
    ...mapActions("user", ["fetchUserProfile"])
  }
};
</script>

<style lang="scss">
.organization-templates {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__heading {
    font-size: 20px;
    font-weight: 600;
  }

  &__create-btn {
    align-self: flex-end;

    &__icon {
      margin-right: 0.5rem;
    }
  }

  &__template-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__template {
    @apply w-full cursor-pointer;
  }

  @media screen and (min-width: 900px) {
    .organization-templates {
      &__template {
        @apply w-auto mr-4 mb-4;
      }
    }
  }
}
</style>
