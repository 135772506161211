var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"templates"},[(_vm.allLoaded)?_c('div',{staticClass:"templates__sidebar--desktop templates__sidebar"},[(_vm.showFirstTemplateLinkBlock)?_c('div',{staticClass:"templates__sidebar-block"},[_c('div',{staticClass:"templates__sidebar-block-heading"},[_vm._v(" "+_vm._s(_vm.team.name)+" ")]),_c('router-link',{staticClass:"templates__sidebar-link",class:{
          'templates__sidebar-link--active':
            _vm.$route.path === '/templates/organization'
        },attrs:{"to":"/templates/organization"}},[_c('inline-svg',{staticClass:"templates__sidebar-link-icon",attrs:{"src":require(`../../assets/icons/Templates.svg`),"aria-label":"Templates"}}),_vm._v(" "+_vm._s(_vm.$t("pages.templates.orga"))+" ")],1),_c('router-link',{staticClass:"templates__sidebar-link",class:{
          'templates__sidebar-link--active':
            _vm.$route.path === '/templates/archive'
        },attrs:{"to":"/templates/archive"}},[_c('inline-svg',{staticClass:"templates__sidebar-link-icon",attrs:{"src":require(`../../assets/icons/Archive.svg`),"aria-label":"Archive"}}),_vm._v(" "+_vm._s(_vm.$t("pages.templates.archive"))+" ")],1)],1):_vm._e(),(_vm.showFirstTemplateLinkBlock)?_c('div',{staticClass:"templates__sidebar-divider"}):_vm._e(),(_vm.showSecondTemplateLinkBlock)?_c('div',{staticClass:"templates__sidebar-block"},[_c('div',{staticClass:"templates__sidebar-block-heading"},[_vm._v(" "+_vm._s(_vm.$t("pages.templates.linked"))+" ")]),_c('router-link',{staticClass:"templates__sidebar-link",class:{
          'templates__sidebar-link--active':
            _vm.$route.path === '/templates/headquarter'
        },attrs:{"to":"/templates/headquarter"}},[(_vm.parentTeam.avatar)?_c('div',{staticClass:"templates__sidebar-link-logo",style:({
            'background-image': 'url(' + _vm.parentTeam.avatar + ')'
          })}):_c('ReplacementLogo',{staticClass:"templates__sidebar-link-logo--unset",attrs:{"name":_vm.parentTeam.name}}),_vm._v(" "+_vm._s(_vm.parentTeam.name)+" ")],1),(_vm.rootTeam && _vm.rootTeam.id !== _vm.parentTeam.id)?_c('router-link',{staticClass:"templates__sidebar-link",class:{
          'templates__sidebar-link--active': _vm.$route.path === '/templates/root'
        },attrs:{"to":"/templates/root"}},[(_vm.rootTeam.avatar)?_c('div',{staticClass:"templates__sidebar-link-logo",style:({
            'background-image': 'url(' + _vm.rootTeam.avatar + ')'
          })}):_c('ReplacementLogo',{staticClass:"templates__sidebar-link-logo--unset",attrs:{"name":_vm.rootTeam.name}}),_vm._v(" "+_vm._s(_vm.rootTeam.name)+" ")],1):_vm._e()],1):_vm._e(),(_vm.showSecondTemplateLinkBlock)?_c('div',{staticClass:"templates__sidebar-divider"}):_vm._e(),(_vm.team.lobbySpaceTemplateAccess)?_c('div',{staticClass:"templates__sidebar-block"},[_c('div',{staticClass:"templates__sidebar-block-heading"},[_vm._v(" "+_vm._s(_vm.$t("pages.templates.discover"))+" ")]),_c('router-link',{staticClass:"templates__sidebar-link",class:{
          'templates__sidebar-link--active':
            _vm.$route.path === '/templates/smart'
        },attrs:{"to":"/templates/smart"}},[_c('inline-svg',{staticClass:"templates__sidebar-link-smart-icon",attrs:{"src":require(`../../assets/icons/AI.svg`),"aria-label":"asdf"}}),_vm._v(" "+_vm._s(_vm.$t("pages.templates.lobbyspace"))+" ")],1)],1):_vm._e()]):_vm._e(),_c('SideBarMobile',{staticClass:"templates__sidebar--mobile",attrs:{"list":_vm.templateLinks}}),_c('div',{staticClass:"templates__banner"},[_c('div',{staticClass:"templates__banner__wrapper"},[_c('div',{staticClass:"templates__banner__text"},[_c('h1',{staticClass:"templates__banner__heading"},[_vm._v(" "+_vm._s(_vm.$t("pages.templates.banner.heading"))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t("pages.templates.banner.text")))])]),_c('img',{staticClass:"templates__banner__image",attrs:{"src":require(`../../assets/img/template-banner-image.png`)}})])]),_c('div',{staticClass:"templates__wrapper"},[_c(_vm.componentName,{tag:"component",staticClass:"templates__content"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }